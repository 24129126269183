import React from 'react';

const shop = props => {
  return (
    <div>
      <h1>shop</h1>
    </div>
  )
}

export default shop;

// import React from "react"
// import { graphql, Link } from "gatsby"
// import Img from "gatsby-image"
// import Layout from "../components/Layout/Layout"
// import LinkButton from "../components/LinkButton"

// import "./shop.scss"

// export const query = graphql`
//   {
//     allShopifyProduct {
//       edges {
//         node {
//           id
//           title
//           handle
//           priceRange {
//             maxVariantPrice {
//               amount
//             }
//           }
//           images {
//             localFile {
//               childImageSharp {
//                 fixed(width: 450) {
//                   ...GatsbyImageSharpFixed
//                 }
//               }
//             }
//           }
//           descriptionHtml
//         }
//       }
//     }
//   }
// `

// const shop = ({ data }) => {
//   const shopifyProducts = data.allShopifyProduct.edges

//   const displayProducts = shopifyProducts
//     .sort((a, b) => {
//       const aPrice = a.node.priceRange.maxVariantPrice.amount
//       const bPrice = b.node.priceRange.maxVariantPrice.amount
//       return bPrice - aPrice
//     })
//     .map(({ node }) => {
//       const price = node.priceRange.maxVariantPrice.amount

//       return (
//         <div className="product" key={node.id}>
//           <div className="product__image-container">
//             <Img
//               className="product__image"
//               fixed={node.images[0].localFile.childImageSharp.fixed}
//             />
//           </div>
//           <h3 className="product__title">{node.title}</h3>
//           <p className="product__price">${parseInt(price).toFixed(2)}</p>
//           <LinkButton className="product__info-button">
//             <Link to={`/shop/${node.handle}`}>View</Link>
//           </LinkButton>
//         </div>
//       )
//     })

//   return (
//     <Layout title="shop">
//       <div className="shop">{displayProducts}</div>
//     </Layout>
//   )
// }

// export default shop
